@import "main.css";
.titleText.text-white.text-center.fs-2.mb-4.mt-lg-5.titles {
  font-size: 2.5rem !important;
}

.display-5.fw-bold.p-4.pt-lg-2.titles {
  font-weight: 300 !important;
  font-size: 2.5rem;
}

.display-6.fw-bold.text-body-emphasis.lh-1.mb-5.titles {
  font-family: bakery;
  font-size: 2.5rem;
}

h2, h3 {
  font-family: 'bakery';
}

.fs-2.fw-700.titles.text-color-sixth {
  font-size: 3rem !important;
}

.mb-4 text-center display-6.titles {
  font-family: 'bakery';
}

.fs-2.fw-700.titles.text-color-sixth {
  font-size: 2.5rem !important;
}

.display-6.text-center.titles.text-color-sixth {
  font-family: 'bakery';
  font-size: 2.5rem;
}

.display-5.titles.mb-2.fw-bold.text-body-emphasis.ctaLinks-title.text-color-sixth {
  font-family: 'bakery';
  font-size: 2.5rem;
}

#place-name {
  font-size: 2.5rem;
}

.px-2.px-lg-4.py-2.bg-sixthColor {
    display: none;
}